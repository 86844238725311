// BASE_URL='https://779375040545.ngrok.io'
// https://b9fc-73-231-24-45.ngrok.io

  //export const BASE_URL = "http://184.168.120.214";
  export const BASE_URL = "https://uday.org.in";
  // export const BASE_URL = "http://localhost:3001";

// export const USERS_ROUTE = "/v1/users";

export const CONTENT_TYPE = "application/json";

export const HEADER_DATA = {
  Accept: CONTENT_TYPE,
  "content-type": CONTENT_TYPE,
};